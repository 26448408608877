import SendIcon from '@mui/icons-material/Send'
import { Cell, Row } from 'components/Table'
import formatters from 'lib/formatters'
import { httpPost } from 'lib/http'
import { unsetModal } from 'lib/modal/actions'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import { map } from 'lodash'
import React from 'react'
import { useDispatch } from 'react-redux'
import { IconRecurring } from '../../../../../components/Icons/IconRecurring'
import { recurring } from '../Main/configuration'
import { deniedRequestRadioOptions } from '../Main/constant'
import { LockUnlockIcon } from '../permissions'
import styles from './styles.css'

export default function RenderDistributionRow({ dist, tableStatus, nextStatus }) {
  const dispatch = useDispatch()

  const scheduledClick = (id) => {
    dispatch(setLoad())
    httpPost(`/admin/recurring_distributions/${id}?action=run`)
      .then(() => {
        dispatch(setBanner('Run success'))
      })
      .catch(() => {
        dispatch(setBanner('Ran unsuccessfully', 'danger'))
      })
      .then(() => {
        dispatch(unsetModal())
        dispatch(setUnload())
      })
  }
  const {
    id,
    account,
    requestType,
    amount,
    lastUpdatedDate,
    lastUpdatedUser,
    expeditedDateNeeded,
    insertedAt,
    paymentType,
    pendingDistribution,
    status,
    xeroPayTo,
    nextPaymentDate,
    owner,
    deniedDistribution,
    lockedForEdit,
    datePaid,
    templateId,
  } = dist
  const isRecurring = templateId != null
  const handleLink = () => {
    const searchParams = new URLSearchParams({ redirection_back: true })
    return `/distributions/${id}?${searchParams.toString()}`
  }

  let cells = []
  const cellToAdd = nextStatus == 'scheduled' && {
    value: 'Run now',
    key: 'run-now',
  }
  const ownerColumn = { value: owner?.name, key: 'owner' }
  const beneficiaryWithAccNumber = {
    key: 'beneficiary',
    value: `${account.number} - ${account.beneficiary}`,
  }
  const recurringColumn = {
    value: <div>{isRecurring && <IconRecurring />} </div>,
    key: 'recurring',
  }

  if (['main', 'paid', 'recurring'].includes(tableStatus)) {
    cells = [
      { value: formatters.dateTimeDiff(insertedAt, 'days'), key: 'daysIn' },
      {
        key: 'expeditedDateNeeded',
        value: expeditedDateNeeded ? <SendIcon sx={{ color: '#8E3132' }} /> : ' ',
      },
      beneficiaryWithAccNumber,
      { key: 'payToName', value: xeroPayTo.name },
      { key: 'amount', value: formatters.money(amount) },
      { key: 'requestType', value: formatters.toTitleCase(requestType) },
      { key: 'lastUpdatedDate', value: formatters.fileTimestamp(lastUpdatedDate) },
      { key: 'lastUpdatedUser', value: lastUpdatedUser },
      { key: 'id', value: id },
    ]
    if (tableStatus === 'main') {
      cells.splice(1, 0, recurringColumn)
    }

    if (nextStatus === 'admin_wrapped_up') {
      cells = map(cells, function (a) {
        return a.key === 'requestType' ? ownerColumn : a
      })
    } else if (['signer_reviewed', 'admin_reviewed', 'paid_today'].includes(nextStatus)) {
      cells.splice(3, 0, ownerColumn)
    }

    if (status != 'received') {
      cells.push({ value: formatters.legiblePaymentType(paymentType), key: 'paymentType' })
    }
    if (status === 'received' || nextStatus === 'written') {
      cells = cells.filter((cell) => !['amount', 'payToName'].includes(cell.key))
    }
    if (nextStatus === 'scheduled') {
      cells = cells
        .slice(2)
        .concat({ key: 'nextPaymentDate', value: formatters.date(nextPaymentDate) })
      if (cellToAdd) {
        cells.unshift(cellToAdd)
      }
    }
    // remove expedited in recurring
    if ([...recurring, 'recurring'].includes(tableStatus)) {
      // cells.splice(1, 0, recurringColumn)
      cells = cells.filter((cell) => cell.key !== 'expeditedDateNeeded')
    }

    // paid
    if (tableStatus == 'paid') {
      // replace last updated with last paid
      cells = cells.map((cell) => {
        if (cell.key === 'lastUpdatedDate') {
          return {
            key: 'datePaid',
            value: formatters.date(datePaid),
          }
        }
        return cell
      })

      cells.splice(3, 0, ownerColumn)
    }
  } else if (tableStatus === 'pending') {
    cells = [
      {
        value:
          pendingDistribution?.dateEnteredPending != null &&
          `${formatters.dateTimeDiff(pendingDistribution?.dateEnteredPending, 'days')}`,
        key: 'dateEnteredPending',
      }, // days pending
      recurringColumn,
      beneficiaryWithAccNumber,
      { value: pendingDistribution?.reason, key: 'reason' }, // reason
      ownerColumn, // owner
      { key: 'lastUpdatedDate', value: formatters.fileTimestamp(lastUpdatedDate) },
      {
        key: 'dateEnteredPending',
        value: formatters.date(pendingDistribution?.dateEnteredPending),
      },
      { value: xeroPayTo.name, key: 'payToName' }, //Pay To
      { value: formatters.money(amount), key: 'amount' }, //amount
      { value: formatters.dateTimeDiff(insertedAt, 'days'), key: 'insertedAt' }, //days in queue
      { value: id, key: 'id' }, //id
    ]
  } else if (tableStatus === 'denied') {
    let deniedReason = null
    if (deniedDistribution?.reason?.length > 1) {
      deniedReason = 'Multiple'
    } else if (deniedDistribution?.reason?.length == 1) {
      deniedReason = deniedRequestRadioOptions.find(
        (req) => req.id == deniedDistribution?.reason?.[0],
      )?.label
    }

    cells = [
      beneficiaryWithAccNumber,
      recurringColumn,
      {
        value: deniedReason,
        key: 'reason',
      }, // reason
      ownerColumn, // owner
      { key: 'lastUpdatedDate', value: formatters.fileTimestamp(lastUpdatedDate) },
      // { value: '----', key: 'dateDenied' }, //days in queue
      { value: xeroPayTo.name, key: 'payToName' }, //Pay To
      { value: formatters.money(amount), key: 'amount' }, //amount
      { value: formatters.dateTimeDiff(insertedAt, 'days'), key: 'insertedAt' }, //days in queue
      { value: 'id', key: 'id' }, //id
    ]
  }
  // if (process.env.NODE_ENV === 'development') {
  cells.push({
    value: <LockUnlockIcon lockedForEdit={lockedForEdit} />,
    className: styles.cellNoPadding,
  })
  // }

  return (
    <Row key={`row-${id}-${lastUpdatedDate}`} link={handleLink()}>
      {cells?.map((cell, index) => {
        const onClick = cell.key == 'run-now' ? () => scheduledClick(id) : null
        return (
          <Cell
            key={`${cell.value}-${index}`}
            value={cell.value}
            onClick={onClick}
            className={cell?.className}
          />
        )
      })}
    </Row>
  )
}
