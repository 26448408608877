import { Dialog } from 'components/Dialog'
import { Notes } from 'components/Notes'
import formatters from 'lib/formatters'
import { httpDelete, httpGet, httpPost, httpPut } from 'lib/http'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import { isEqual } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { encodedData } from '../../../../../lib/encrypt.js'
import ActionsNeeded from '../Footer/ActionsNeeded.js'
import MainCtaFooter from '../Footer/MainCtaFooter.js'
import DrfHeader from '../HeaderStrip/DrfHeader.js'
import {
  areAllRequiredActionsCompleted,
  denied,
  getColor,
  getHeader,
  isMainQueue,
  main,
  pending,
} from './configuration.js'
import { ButtonText } from './confirmationText.js'
import { ActionsEnum, recurringStatus } from './constant.js'
import ReviewForm from './forms/ReviewForm.js'
import WriteFormOne from './forms/WriteFormOne.js'
import WriteFormTwo from './forms/WriteFormTwo.js'
import { DeniedSection, PendingSection } from './ModalSection.js'
import { PendingRequest } from './PendingRequest.js'
import styles from './styles'
import SectionHeader from '../Header/SectionHeader.js'
import { useSearchParams } from 'react-router-dom'

const DistributionMain = () => {
  const params = useParams()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = params.id
  const [formInit, setFormInit] = useState(null)
  const [formInitPendingDistribution, setFormInitPendingDistribution] = useState(null)
  const [form, setForm] = useState({
    account: '',
    accountNumber: '',
    accountContacts: [],
    amount: '',
    // checkNumber: '',
    dateReceived: '',
    description: '',
    expeditedPaymentNeeded: '',
    expeditedDateNeeded: '',
    events: [],
    journalName: '',
    instructions: '',
    notes: [],
    paymentType: '',
    paymentSubtype: '',
    xeroPayTo: {},
    defaultXeroPayTo: {},
    xeroLink: '',
    // reference: '',
    requestType: '',
    requestorId: '',
    recurringDistribution: {
      dayRepeats: '',
      startMonth: '',
      endDate: '',
      period: 'weekly',
      requested: false,
    },
    shortCode: '',
    otherDistributions: null,
    expeditedMailing: false,
    mailingFeeAssessed: false,
    templateId: null,
  })

  const [files, setFiles] = useState([])
  const dispatch = useDispatch()
  const [currentStatus, setCurrentStatus] = useState('')
  const [step, setStep] = useState(0)
  const [isApproved, setIsApproved] = useState(null)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [showUnableToProcess, setShowUnableToProcess] = useState(false)
  const [approveButtonText, setApproveButtonText] = useState('')
  const [disapproveButtonText, setDisapproveButtonText] = useState('Unable to Process at this Time')
  const [headerTitle, setHeaderTitle] = useState('')
  const [recurringDrf, setRecurringDrf] = useState(false)
  const [currentDrfRecurring, setCurrentDrfRecurring] = useState(false)
  const [recurringFormDisabled, setRecurringFormDisabled] = useState(false)
  const [xeroLink, setXeroLink] = useState('')
  const [invoiceXeroLink, setInvoiceXeroLink] = useState(null)
  const [expeditedDate, setExpeditedDate] = useState(null)
  const [moveToPending, setMoveToPending] = useState(false)
  const [moveToDenied, setMoveToDenied] = useState(false)
  const [clientSelect, setClientSelect] = useState([])
  const [deniedReasonSelect, setDeniedReasonSelect] = useState([])
  const [trustSelect, setTrustSelect] = useState()
  const [formDisabled, setFormDisabled] = useState(false)
  const [daysInPending, setDaysInPending] = useState(null)
  const [nextStatus, setNextStatus] = useState('')
  const [color, setColor] = useState('cyan')
  const [events, setEvents] = useState([])
  const [accounts, setAccounts] = useState([])
  // Actions
  const [requiredActions, setRequiredActions] = useState([])
  const [completedActions, setCompletedActions] = useState([])
  const [lockedForEdit, setLockedForEdit] = useState(null)
  const updateForm = (e) => {
    if (e.target.name !== 'amount') {
      e.persist()
    }
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const getXeroLink = (id) => {
    const journalName = form?.account?.journalName
    httpGet(`/admin/xero-link?journalName=${journalName}&id=${id}`)
      .then((res) => {
        setXeroLink(res?.xeroLink)
      })
      .catch(() => {
        dispatch(setBanner('There was an error while getting xero link', 'danger'))
      })
  }

  // const handleAccountContactSelect = async (contact) => {
  //   setForm((prev) => ({ ...prev, requestorId: contact.id }))
  // }

  const handleContactSelect = async (contact) => {
    setForm((prev) => ({ ...prev, xeroPayTo: contact }))
    getXeroLink(contact?.id)
  }

  const clearContactSelect = () => {
    setForm((prev) => ({ ...prev, xeroPayTo: {} }))
  }

  const handleAccountSelect = (account) => {
    setForm((prev) => {
      if (prev.account && prev.account.number !== account.number) {
        return { ...prev, account: account, xeroPayTo: {} }
      } else {
        return { ...prev, account: account }
      }
    })
  }

  const handleConfirmationConfirm = (event) => {
    event?.preventDefault()
    setShowConfirmationDialog(false)
    if (approveButtonText.trim() === 'Ready to Process' && ['pending', 'aged_pending'].includes(form.status)) {
      doUpdate('restore_from_pending')
      return
    } else if (form.status === 'denied' && !isSaveRequired()) {
      doUpdate('denied_closed')
      return
    }
    doUpdate()
  }
  const handleConfirmationCancel = () => {
    setShowConfirmationDialog(false)
    setIsApproved(null)
  }
  const handleMoveToPending = () => {
    setMoveToPending(false)
    doUpdate('pending')
  }
  const handleMoveToDenied = () => {
    setMoveToDenied(false)
    doUpdate('denied')
  }

  const handleUnableProcessConfirm = () => {
    setShowUnableToProcess(false)
    setMoveToPending(true)
  }

  const handleUnableProcessCancel = () => {
    setShowUnableToProcess(false)
    setMoveToDenied(true)
    setIsApproved(null)
  }

  const handleRecurringUpdate = (e) => {
    const { name, value } = e.target
    setForm((prev) => ({
      ...prev,
      recurringDistribution: {
        ...prev.recurringDistribution,
        [name]: value,
      },
    }))
  }

  const fetchData = (id) => {
    if (id) {
      dispatch(setLoad())
      httpGet(`/admin/distributions/${id}`)
        .then(({ distribution }) => {
          setEvents(distribution?.events)
          setForm({
            ...distribution,
            defaultXeroPayTo: distribution?.xeroPayTo,
            expeditedPaymentNeeded: distribution.expeditedDateNeeded ? 'yes' : 'no',
            expeditedMailing: distribution.expeditedMailing ? 'yes' : 'no',
            mailingFeeAssessed: distribution.mailingFeeAssessed ? 'yes' : 'no',
          })

          setFormInit({
            ...distribution,
            defaultXeroPayTo: distribution?.xeroPayTo,
            expeditedPaymentNeeded: distribution.expeditedDateNeeded ? 'yes' : 'no',
            expeditedMailing: distribution.expeditedMailing ? 'yes' : 'no',
            mailingFeeAssessed: distribution.mailingFeeAssessed ? 'yes' : 'no',
          })
          setExpeditedDate(distribution.expeditedDateNeeded)
          setCurrentStatus(distribution.status)
          setStep(distribution.step)
          setNextStatus(distribution.nextStatus)
          //TODO: validate below with status
          const isCurrentStatusRecurringDrf =
            recurringStatus.includes(distribution.status) ||
            recurringStatus.includes(distribution.nextStatus)
          setRecurringDrf(isCurrentStatusRecurringDrf)
          setCurrentDrfRecurring(
            distribution.recurringDistribution != null &&
              distribution.recurringDistribution?.period != null &&
              !recurringStatus.includes(distribution.status),
          )
          const isLockedForEdit =
            distribution?.lockedForEdit != null && distribution?.lockedForEdit == true
          setRecurringFormDisabled(!isCurrentStatusRecurringDrf || isLockedForEdit)
          setFiles(distribution.files)
          setStatusBasedTexts(distribution.nextStatus, distribution.status)
          setXeroLink(distribution.xeroPayTo.xeroLink)
          setInvoiceXeroLink(distribution.xeroLink)
          setLockedForEdit(isLockedForEdit)

          setDeniedReasonSelect(distribution?.deniedDistribution?.reason)

          const formDisabledState =
            ['paid', 'denied_closed', 'recurring_expired'].includes(distribution.status) ||
            ['admin_wrapped_up'].includes(distribution.nextStatus) ||
            isLockedForEdit
          setFormDisabled(formDisabledState)
          if (['pending', 'aged_pending'].includes(distribution.status)) {
            setClientSelect(distribution?.pendingDistribution?.clientResponsibility)
            setTrustSelect(distribution?.pendingDistribution?.gtResponsibility)

            setFormInitPendingDistribution({
              clientResponsibility: distribution?.pendingDistribution?.clientResponsibility,
              gtResponsibility: distribution?.pendingDistribution?.gtResponsibility,
            })
            if (distribution?.pendingDistribution?.dateEnteredPending) {
              setDaysInPending(
                formatters.dateTimeDiff(
                  distribution.pendingDistribution.dateEnteredPending,
                  'days',
                ),
              )
            }
          }
          setRequiredActions(distribution?.requiredActions)
          setCompletedActions(distribution?.completedActions)
          setColor(getColor(distribution?.nextStatus ?? distribution?.status))
          dispatch(setUnload())
        })
        .catch((err) => {
          navigate(-1)
          dispatch(
            setBanner(err?.message ?? 'Something went worng... Please try again later', 'danger'),
          )
          dispatch(setUnload())
        })
    }
  }
  const doUpdate = (statusArg) => {
    const url = `/admin/distributions/${id}`

    const recurringDistribution = recurringDrf
      ? {
          period: form.recurringDistribution.period,
          day_repeats: form.recurringDistribution.dayRepeats,
          end_date: form.recurringDistribution.endDate,
          start_month: form.recurringDistribution.startMonth,
        }
      : {}
    let putStatus
    const isSaveEnabled = isSaveRequired()
    if (statusArg) {
      putStatus = statusArg
    } else if (isSaveEnabled) {
      putStatus = currentStatus
    } else {
      putStatus = nextStatus
    }

    const params = {
      account_number: form.account.number,
      amount: form.amount,
      // check_number: form.checkNumber,
      date_received: form.dateReceived,
      description: form.description,
      expedited_date_needed: form.expeditedDateNeeded,
      instructions: form.instructions,
      xero_pay_to: form.xeroPayTo,
      payment_type: form.paymentType,
      payment_subtype: form.paymentSubtype,
      recurring_distribution: {
        requested: `${form?.recurringDistribution?.requested}` == 'true',
        ...recurringDistribution,
      },
      reference: form.reference,
      request_type: form.requestType,
      status: putStatus,
      step: step,
      pending_distribution: {
        client_responsibility: clientSelect,
        gt_responsibility: trustSelect,
      },
      denied_reason: deniedReasonSelect,
      requestor_id: form.requestorId,
      completed_actions: completedActions,
      expedited_mailing: form.expeditedMailing == 'yes',
      mailing_fee_assessed: form.mailingFeeAssessed == 'yes',
    }
    dispatch(setLoad())
    const isStatusRedirection = (status) => {
      const validStatuses = ['restore_from_pending', ...pending]

      if (
        ['admin_reviewed'].includes(status) &&
        ['check_in_house', 'cashier_check'].includes(form.paymentType)
      ) {
        return true
      }
      return validStatuses.includes(status)
    }
    const handleNavigation = () => {
      if (searchParams.get('redirection_back') == 'true') {
        navigate(-1)
      } else {
        navigate(`/distributions/queue?tid=${encodedData('main')}&stid=${encodedData('0')}`)
      }
    }

    httpPut(url, params)
      .then(() => {
        dispatch(setBanner('Distribution saved successfully'))
        dispatch(setUnload())
        if (!isSaveEnabled && !isStatusRedirection(putStatus) && !isStatusRedirection(nextStatus)) {
          handleNavigation()
        } else {
          fetchData(id)
        }
      })
      .catch((err) => {
        dispatch(setBanner(`Error saving distribution - ${err}`, 'danger'))
        dispatch(setUnload())
      })
  }

  const buildUploadParams = async (fileName, file) => {
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })

    const data = await toBase64(file)
    const params = {
      content_type: file.type,
      name: fileName,
      data: data.split(',')[1],
    }

    return params
  }

  const onUploadClick = (fileName, file) => {
    buildUploadParams(fileName, file).then((params) => {
      const url = `/admin/distributions/${id}/files`

      dispatch(setLoad())
      httpPost(url, params)
        .then((data) => {
          dispatch(setBanner('File uploaded'))
          setFiles(data.files)
          dispatch(setUnload())
        })
        .catch((err) => {
          dispatch(setBanner(`Error saving - ${err}`, 'danger'))
          dispatch(setUnload())
        })
    })
  }

  const onDeleteClick = (fileId) => {
    const url = `/admin/distributions/files/${fileId}`

    httpDelete(url)
      .then((res) => {
        setFiles(files.filter((file) => file.id !== fileId))
        dispatch(setBanner('File deleted'))
      })
      .catch((err) => {
        dispatch(setBanner(`Error deleting - ${err}`, 'danger'))
      })
  }

  const getActionsNeeded = () => {
    // only for WRIITE
    if (nextStatus !== 'written') return
    if (!form.paymentType) {
      return null
    }
    dispatch(setLoad())
    const url = `/admin/distributionActions?payment_type=${form.paymentType}`
    httpGet(url)
      .then((res) => {
        setRequiredActions(res?.requiredActions)
        dispatch(setUnload())
      })
      .catch((err) => {
        dispatch(setBanner(err, 'danger'))
        dispatch(setUnload())
      })
  }

  const setStatusBasedTexts = (nextStatus, status) => {
    switch (nextStatus) {
      case 'written':
        setHeaderTitle(ActionsEnum.WRITE)
        setApproveButtonText(ActionsEnum.READY_TO_REVIEW)
        break
      case 'admin_reviewed':
        setHeaderTitle(ActionsEnum.ADMIN_REVIEW)
        setApproveButtonText(ActionsEnum.APPROVE)
        break
      case 'signer_reviewed':
        setHeaderTitle(ActionsEnum.SIGNER_REVIEW)
        setApproveButtonText(ActionsEnum.APPROVE)
        break
      case 'recurring_written':
        setHeaderTitle(ActionsEnum.RECURRING_DRF)
        setApproveButtonText(ActionsEnum.APPROVE)
        break
      case 'recurring_reviewed':
        setHeaderTitle(ActionsEnum.RECURRING_DRF)
        setApproveButtonText(ActionsEnum.READY_TO_REVIEW)
        break
      case 'admin_wrapped_up':
        setHeaderTitle(ActionsEnum.ADMIN_WRAP_UP)
        setApproveButtonText(ActionsEnum.PAID)
        break
      case 'recurring_approved':
      case 'scheduled':
      case 'expired':
        setHeaderTitle(ActionsEnum.RECURRING_DRF)
        setApproveButtonText(ActionsEnum.APPROVE)
        break
      default:
        setHeaderTitle(ActionsEnum.SAVE_REQUEST)
        setApproveButtonText(ActionsEnum.SAVE_UPDATES)
    }

    switch (status) {
      case 'pending':
      case 'aged_pending':
        setHeaderTitle('PENDING DRF')
        setApproveButtonText(ActionsEnum.READY_TO_PROCESS)
        setDisapproveButtonText(ActionsEnum.CLOSE_REQUEST)
        break
      case 'paid':
        setHeaderTitle('PAID DRF')
        break
      case 'denied':
        setHeaderTitle(ActionsEnum.DENIED_DRF)
        setApproveButtonText(ActionsEnum.CLOSE_REQUEST)
        break
      case 'denied_closed':
        setHeaderTitle(ActionsEnum.DENIED_CLOSED_DRF)
        break
      case 'recurring_expired':
        setHeaderTitle(ActionsEnum.RECURRING_EXPIRED)
        break
      default:
        setDisapproveButtonText('Unable to Process at this Time')
    }
    const btnText = isSaveRequired() ? ActionsEnum.SAVE_UPDATES : null
    btnText && setApproveButtonText(btnText)
  }

  const isSaveRequired = () => {
    // if actions required is same as distribution actions required
    const anyFieldPending = !isEqual(form, formInit)
    const actionsCompleted = areAllRequiredActionsCompleted(completedActions, requiredActions)

    // save required, for pending status below
    const pendingBase = clientSelect?.length > 0 || trustSelect != null

    if (['pending', 'aged_pending'].includes(form.status)) {
      return pendingBase
    }
    // bypass anyFieldPending check if required actions completed
    if (
      [...main, ...recurringStatus].includes(form.nextStatus) &&
      requiredActions.length != 0 &&
      actionsCompleted
    ) {
      return false
    }
    if (anyFieldPending) {
      return true
    } else if (actionsCompleted) {
      return false
    }
    return true
  }

  const accountContactSearch = (accountNumber) => {
    httpGet(`/admin/distributions/contacts?number=${accountNumber}`)
      .then(({ accountContacts }) => {
        setAccounts(accountContacts)
      })
      .catch(() => {})
  }

  const stepper = getHeader({ headerTitle, currentStatus, nextStatus, form, id, events })

  // To be removed
  useEffect(() => {
    if (isApproved !== null) {
      if (!isApproved) {
        setShowUnableToProcess(true)
      } else {
        setShowConfirmationDialog(true)
      }
    }
  }, [isApproved])

  useEffect(() => {
    setStatusBasedTexts(nextStatus, form.status)
  }, [
    form,
    formInitPendingDistribution,
    formInit,
    trustSelect,
    clientSelect,
    requiredActions,
    completedActions,
  ])

  useEffect(() => {
    if (form.account?.number) {
      accountContactSearch(form.account?.number)
    }
  }, [form.account])

  useEffect(() => {
    if (form.expeditedPaymentNeeded !== 'yes') {
      setForm((prev) => ({ ...prev, expeditedDateNeeded: '' }))
      setFormInit((prev) => ({ ...prev, expeditedDateNeeded: '' }))
    }
  }, [form.expeditedPaymentNeeded])

  useEffect(() => {
    fetchData(id)
  }, [id])

  useEffect(() => {
    getActionsNeeded()
  }, [form.paymentType])

  useEffect(() => {
    isSaveRequired()
  }, [form, formInit])

  return (
    <>
      <form className={styles.form}>
        <DrfHeader
          stepper={stepper}
          color={color}
          expeditedDate={expeditedDate}
          daysInPending={daysInPending}
          currentDrfRecurring={currentDrfRecurring}
          lockedForEdit={lockedForEdit}
        />
        <div className={styles.distributionFormContainer}>
          <WriteFormOne
            onUploadClick={onUploadClick}
            files={files}
            onDeleteClick={onDeleteClick}
            form={form}
            updateForm={updateForm}
            formDisabled={formDisabled}
            handleAccountSelect={handleAccountSelect}
            currentDrfRecurring={currentDrfRecurring}
            recurringFormDisabled={recurringFormDisabled}
            recurringDrf={recurringDrf}
            nextStatus={nextStatus}
            handleRecurringUpdate={handleRecurringUpdate}
            lockedForEdit={lockedForEdit}
          />

          <WriteFormTwo
            form={form}
            updateForm={updateForm}
            handleContactSelect={handleContactSelect}
            clearContactSelect={clearContactSelect}
            xeroLink={xeroLink}
            nextStatus={nextStatus}
            formDisabled={formDisabled}
            handleRecurringUpdate={handleRecurringUpdate}
            recurringDrf={recurringDrf}
            lockedForEdit={lockedForEdit}
            invoiceXeroLink={invoiceXeroLink}
            accounts={accounts}
          />
          <ReviewForm form={form} />
        </div>
      </form>

      <div className={styles.footerContainer}>
        <div className={styles.notesList}>
          <Notes distributionId={id} dispatch={dispatch} notes={form.notes} type="distribution" />
          {['pending', 'aged_pending'].includes(currentStatus) && (
            <PendingRequest
              clientSelect={clientSelect}
              setClientSelect={setClientSelect}
              trustSelect={trustSelect}
              setTrustSelect={setTrustSelect}
            />
          )}
          {denied.includes(currentStatus) && (
            <section className={styles.cardMarginBot}>
              <SectionHeader title={'Denied Reason'} />
              <div className={styles.actionsNeededContainer}>
                <DeniedSection
                  deniedReasonSelect={deniedReasonSelect}
                  setDeniedReasonSelect={setDeniedReasonSelect}
                  showTitle={false}
                />
              </div>
            </section>
          )}
          {requiredActions?.length > 0 && (
            <ActionsNeeded
              completedActions={completedActions}
              requiredActions={requiredActions}
              setCompletedActions={setCompletedActions}
              checkColor={isMainQueue(currentStatus) ? 'green' : 'red'}
              sectionTitle={denied.includes(currentStatus) ? 'Closing Actions' : null}
            />
          )}
        </div>
        <MainCtaFooter
          form={form}
          formDisabled={formDisabled}
          disapproveButtonText={disapproveButtonText}
          approveButtonText={approveButtonText}
          files={files}
          recurringDrf={recurringDrf}
          nextStatus={nextStatus}
          handleConfirmationConfirm={handleConfirmationConfirm}
          setShowUnableToProcess={setShowUnableToProcess}
          isSaveRequired={isSaveRequired}
        />
      </div>

      {showConfirmationDialog && (
        <Dialog
          cancelButtonText="Cancel"
          confirmButtonText="OK"
          // headerText={confirmationDialogText}
          onCancel={handleConfirmationCancel}
          onConfirm={handleConfirmationConfirm}
        >
          {/* {confirmationDialogText} */}
        </Dialog>
      )}
      {showUnableToProcess && (
        <Dialog
          cancelButtonText={
            <ButtonText
              primary={'No'}
              secondary={
                <>
                  Move to <p>Denied</p>
                </>
              }
            />
          }
          confirmButtonText={
            <ButtonText
              primary={'Yes'}
              secondary={
                <>
                  Move to <p>Pending</p>
                </>
              }
            />
          }
          headerText={
            <div>
              Can we <i>possibly</i> get the information we need to process this request?
            </div>
          }
          onCancel={handleUnableProcessCancel}
          onConfirm={handleUnableProcessConfirm}
        />
      )}

      {moveToPending && (
        <Dialog
          cancelButtonText="Cancel"
          confirmButtonText="Move to Pending"
          bodyText={
            <PendingSection
              clientSelect={clientSelect}
              setClientSelect={setClientSelect}
              trustSelect={trustSelect}
              setTrustSelect={setTrustSelect}
            />
          }
          onCancel={() => {
            setMoveToPending(false)
          }}
          disabled={clientSelect?.length == 0 && trustSelect == null}
          onConfirm={handleMoveToPending}
        />
      )}
      {moveToDenied && (
        <Dialog
          cancelButtonText="Cancel"
          confirmButtonText="Move to Denied"
          bodyText={
            <DeniedSection
              deniedReasonSelect={deniedReasonSelect}
              setDeniedReasonSelect={setDeniedReasonSelect}
              showTitle
            />
          }
          onCancel={() => {
            setMoveToDenied(false)
          }}
          disabled={deniedReasonSelect?.length == 0}
          onConfirm={handleMoveToDenied}
        />
      )}
    </>
  )
}

export default DistributionMain
