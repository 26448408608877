import XeroContactSearch from 'components/XeroContactSearch'
import XeroLink from 'components/XeroLink'
import React from 'react'
import CurrencyFormatInput from '../../../../../../components/CurrencyFormatInput/index.js'
import SectionHeader from '../../Header/SectionHeader.js'
import styles from '../styles'
import formatters from 'lib/formatters.js'
import { Link } from 'react-router-dom'
import { httpGet } from 'lib/http'
import query_string from 'query-string'
import { useDispatch } from 'react-redux'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'

export default function WriteFormTwo({
  form,
  updateForm,
  handleContactSelect,
  clearContactSelect,
  xeroLink,
  nextStatus,
  formDisabled,
  handleRecurringUpdate,
  recurringDrf,
  lockedForEdit,
  invoiceXeroLink,
  accounts,
}) {
  const dispatch = useDispatch()

  const handleXeroContactRefresh = (event) => {
    event.preventDefault()
    if (!form.xeroPayTo.id) {
      dispatch(setBanner('No Contact Found...', 'danger'))
      return
    }
    dispatch(setLoad())
    const queryString = query_string.stringify({
      journalName: form.account.journalName,
      contactId: form.xeroPayTo.id,
    })

    httpGet(`/admin/xero-contact-refresh?${queryString}`)
      .then(({ contact }) => {
        handleContactSelect(contact)
      })
      .catch((err) => {
        // display banner
        dispatch(setBanner(err, 'danger'))
      })
      .finally(() => {
        dispatch(setUnload())
      })
  }

  const newContactUrl = () => {
    return 'http://go.xero.com/app/' + form.shortCode + '/contacts/add'
  }

  const contactExists = (id) => form.accountContacts?.find((contact) => contact.id == id)

  const getMailUsingId = (id) => {
    const contact = contactExists(id)
    if (!contact) return null
    const subject =
      'We%20have%20received%20your%20request&body=We%20have%20received%20your%20distribution%20request%20and%20will%20process%20it%20within%203%20to%205%20business%20days.'
    return `mailto:${contact.email}?subject=${subject}`
  }

  const hasInvoiceId = invoiceXeroLink != null

  return (
    <div className={styles.sectionTwo}>
      {recurringDrf && (
        <div className={styles.cardMarginBot}>
          {formDisabled && <div className={styles.disabledMask}></div>}
          <SectionHeader title="Related DRF's" />
          <section className={styles.sectionNoMargin}>
            <div className={styles.formItemHalf}>
              <div className={styles.associatedDrfTitle}>
                Paid distribution that requested recurring
              </div>
              <div className={styles.associatedDrf}>
                <p>{formatters.date(form.recurringDistribution.requestedFromDate)}</p>
                <Link
                  to={`/distributions/${form.recurringDistribution.requestedFromId}`}
                  className={styles.requestInfoItem}
                  target="_blank"
                  referrerPolicy="no-referrer"
                >
                  ID {form.recurringDistribution.requestedFromId}
                </Link>
              </div>
            </div>
          </section>
        </div>
      )}
      <div className={styles.cardMarginBot}>
        {((formDisabled && hasInvoiceId) || lockedForEdit) && (
          <div className={styles.disabledMask}></div>
        )}
        <SectionHeader title="Write Info" />

        <section className={styles.sectionNoMargin}>
          <section className={styles.subSection}>
            <div className={styles.formItemHalf}>
              <label className={styles.label} htmlFor="payTo">
                Who is making the request?
              </label>
              <div>
                <select
                  className={`${styles.select} ${styles.requestor}`}
                  name="requestorId"
                  value={form.requestorId || ''}
                  onChange={updateForm}
                  required
                >
                  <option value="">Choose Contact</option>
                  {accounts?.map((contact, index) => {
                    return (
                      <option value={contact.id} key={`${contact.id}-${index}`}>
                        {contact.name} - {contact.role}
                      </option>
                    )
                  })}
                </select>
                {form.requestorId != null && contactExists(form.requestorId) != null && (
                  <div className={styles.requestorLink}>
                    <a href={getMailUsingId(form.requestorId)}>Send requestor email</a>
                  </div>
                )}
              </div>
            </div>
          </section>
          <section className={styles.subSection}>
            <div className={styles.formItemMono}>
              <label className={styles.label} htmlFor="amount">
                Request Amount
              </label>
              <CurrencyFormatInput
                disabled={invoiceXeroLink != null}
                className={`${styles.input} ${styles.amountInput}`}
                name="amount"
                setValue={updateForm}
                placeholder="$0.00"
                value={form.amount}
                required
              />
            </div>
          </section>
          <section className={styles.subSection}>
            <div className={styles.formItemHalf}>
              <label className={styles.label} htmlFor="payTo">
                Pay To
              </label>
              <div className={styles.searchComponent}>
                <XeroContactSearch
                  disabled={form.account === '' || invoiceXeroLink != null}
                  journalName={form.account && form.account.journalName}
                  onContactSelect={handleContactSelect}
                  valueInsideInput={form.xeroPayTo.name ?? null}
                  clearContactSelect={clearContactSelect}
                  inputType="textarea"
                />
              </div>
              <div className={styles.payTo}>
                <div className={styles.xeroLinkItem}>
                  {form.xeroPayTo && form.xeroPayTo.name ? (
                    <div>
                      <div className={styles.xeroItem}>
                        <XeroLink xeroUrl={xeroLink ?? form.xeroLink} />
                        <div className={styles.payToAddress}>
                          <span className={styles.subText}>{form.xeroPayTo.street}</span>
                          {form.xeroPayTo.city || form.xeroPayTo.state ? (
                            <span className={styles.subText}>
                              {form.xeroPayTo.city}, {form.xeroPayTo.state} {form.xeroPayTo.zip}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <a
                      href={newContactUrl()}
                      // className={styles.xeroRefresh}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Create new contact in Xero
                    </a>
                  )}
                  <div className={styles.xeroRefresh} onClick={handleXeroContactRefresh}>
                    Refresh Address from Xero
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className={styles.subSection}>
            <div className={styles.formItemMono}>
              <label className={styles.label} htmlFor="reference">
                Reference field of transaction
              </label>
              <textarea
                disabled={invoiceXeroLink != null}
                className={styles.textareaSmall}
                type="text"
                name="reference"
                value={form.reference || ''}
                onChange={updateForm}
                rows="2"
                required
              />
            </div>
          </section>
          <section className={styles.subSection}>
            <div className={styles.formItemMono}>
              <label className={styles.label} htmlFor="description">
                Transaction Description - seen on client statements
              </label>
              <textarea
                disabled={invoiceXeroLink != null}
                className={styles.textareaSmall}
                type="text"
                name="description"
                value={form.description || ''}
                onChange={updateForm}
                rows="2"
                required
              />
            </div>
          </section>
          <section className={styles.subSection}>
            <div className={styles.formItemHalf}>
              <label className={styles.label} htmlFor="paymentType">
                Payment Type
              </label>
              <div>
                <select
                  className={styles.select}
                  name="paymentType"
                  value={form.paymentType || ''}
                  onChange={updateForm}
                  disabled={!['recurring_written', 'written'].includes(nextStatus)}
                  required
                >
                  <option value="">Choose Type</option>
                  <option value="wire">Wire</option>
                  <option value="ach">ACH</option>
                  <option value="cashier_check" disabled={recurringDrf}>
                    Cashier Check
                  </option>
                  <option value="check_in_house">Print - In House</option>
                  {form.amount < 10000 && (
                    <>
                      <option value="check_external">Check - External</option>
                      <option value="pay_by_phone">Pay by Phone</option>
                      <option value="pay_online">Pay Online</option>{' '}
                    </>
                  )}
                </select>
              </div>
            </div>
            {['pay_by_phone', 'pay_online'].includes(form.paymentType) && (
              <div className={styles.formItem}>
                <label className={styles.label} htmlFor="instructions">
                  Instructions
                </label>
                <div>
                  <textarea
                    className={styles.textarea}
                    name="instructions"
                    placeholder="Please include details about how to complete this payment, i.e., phone number, website address, etc."
                    value={form.instructions || ''}
                    onChange={updateForm}
                    rows="4"
                    required
                  />
                </div>
              </div>
            )}
            {['pay_by_phone', 'pay_online'].includes(form.paymentType) && (
              <div className={styles.formItemHalf}>
                <label className={styles.label} htmlFor="paymentSubtype">
                  Payment Method
                </label>
                <div>
                  <select
                    className={styles.select}
                    name="paymentSubtype"
                    value={form.paymentSubtype || ''}
                    onChange={updateForm}
                    required
                  >
                    <option value="">Choose Type</option>
                    <option value="e_check">E-Check</option>
                    <option value="gt_credit_card">GT Credit Card</option>
                  </select>
                </div>
                {form.paymentSubtype === 'gt_credit_card' && (
                  <span className={styles.caption}>
                    Please make a note with last four digits of the credit card you used.
                  </span>
                )}
              </div>
            )}

            {/* checkboxes */}
            {['cashier_check', 'check_in_house'].includes(form.paymentType) && (
              <>
                <section className={styles.subSection}>
                  <div className={styles.radioButtonGroup}>
                    <label className={styles.label} htmlFor="expeditedMailing">
                      Paid mailing needed?
                    </label>
                    <div className={styles.buttons}>
                      <input
                        type="radio"
                        name="expeditedMailing"
                        value="yes"
                        disabled={!['recurring_written', 'written'].includes(nextStatus)}
                        checked={form.expeditedMailing === 'yes'}
                        onChange={updateForm}
                      />
                      <label htmlFor="yes">Yes</label>
                      <input
                        type="radio"
                        disabled={!['recurring_written', 'written'].includes(nextStatus)}
                        name="expeditedMailing"
                        value="no"
                        checked={form.expeditedMailing === 'no'}
                        onChange={updateForm}
                      />
                      <label htmlFor="no">No</label>
                    </div>
                  </div>
                </section>
                {form.expeditedMailing === 'yes' && (
                  <section className={styles.subSection}>
                    <div className={styles.radioButtonGroup}>
                      <label className={styles.label} htmlFor="mailingFeeAssessed">
                        Charge mailing fee to client?
                      </label>
                      <div className={styles.buttons}>
                        <input
                          type="radio"
                          name="mailingFeeAssessed"
                          value="yes"
                          disabled={!['recurring_written', 'written'].includes(nextStatus)}
                          checked={form.mailingFeeAssessed === 'yes'}
                          onChange={updateForm}
                        />
                        <label htmlFor="yes">Yes</label>
                        <input
                          type="radio"
                          name="mailingFeeAssessed"
                          disabled={!['recurring_written', 'written'].includes(nextStatus)}
                          value="no"
                          checked={form.mailingFeeAssessed === 'no'}
                          onChange={updateForm}
                        />
                        <label htmlFor="no">No</label>
                      </div>
                    </div>
                  </section>
                )}
              </>
            )}
          </section>
        </section>
      </div>
      {form.templateId == null && !recurringDrf && (
        <div className={styles.cardMarginBot}>
          {formDisabled && <div className={styles.disabledMask}></div>}
          <SectionHeader title="Recurring" />
          <section className={styles.sectionNoMargin}>
            <div className={styles.radioButtonGroup}>
              <label className={styles.label} htmlFor="requested">
                Recurring Payment Request
              </label>
              <div className={styles.buttons}>
                <input
                  type="radio"
                  name="requested"
                  value={'true'}
                  checked={`${form?.recurringDistribution?.requested}` == 'true'}
                  onChange={handleRecurringUpdate}
                />
                <label htmlFor="requested">Yes</label>
                <input
                  type="radio"
                  name="requested"
                  value={'false'}
                  checked={
                    `${form?.recurringDistribution?.requested}` == 'false' ||
                    form?.recurringDistribution?.requested == null
                  }
                  onChange={handleRecurringUpdate}
                />
                <label htmlFor="no">No</label>
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  )
}
